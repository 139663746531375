const  router = [
	{
		path: "/statistics/Layout",
		component: () => import('../../../pages/statistics/Layout.vue'),
		meta: {title: '统计'},
		name: 'statistics_layout',
		children:[
			{
				path: "/statistics/serviceman",
				component: () => import('../../../pages/statistics/serviceman.vue'),
				meta: {title: '服务人员'},
				name: 'mall_statistics'
			},
            {
                path: "/statistics/projectcategory",
                component: () => import('../../../pages/statistics/projectcategory.vue'),
                meta: {title: '项目分类统计'},
                name: 'statistics_projectcategory'
            },
			{
				path: "/statistics/active",
				component: () => import('../../../pages/statistics/active.vue'),
				meta: {title: '活动统计'},
				name: 'statistics_active'
			},
			{
				path: "/statistics/dolog",
				component: () => import('../../../pages/statistics/dolog.vue'),
				meta: {title: '操作记录'},
				name: 'statistics_dolog'
			},
		],
	}
];

export default router