import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";
Vue.use(VueRouter);

const routerList = [
	{
	    path: '/404',
	    component: () => import( '../../pages/404.vue'),
	    name:'nodata404',
	    meta: {title: '抱歉您找的页面失踪了'},
	},
	{
	    path: '/auth',
	    component: () => import( '../../pages/Auth.vue'),
	    name:'auth',
	    meta: {title: '登录校验中'},
	},
	{
		path: '/login',
		component: () => import( '../../pages/Login.vue'),
		name:'login',
		meta: {title: '登录页面'},
	},

];

const storeRouter = {
	    path: "/layout",
	    component: () => import('../../pages/Layout.vue'),
	    meta: {title: '总后台'},
	    name: 'layout',
		children:[
			{
			    path: '/refresh',
			    component: () => import( '../../pages/Refresh.vue'),
			    name:'refresh',
			    meta: {title: '刷新页面'},
			}
		]
}
const files = require.context('./routers', false, /\.js$/)


files.keys().forEach(key => {
	let menuLocal = files(key).default;
	for(var  a in menuLocal){
		storeRouter.children.push(menuLocal[a]);
	}
})

const adminFiles = require.context('./admin', false, /\.js$/)
adminFiles.keys().forEach(key => {
	let menuLocal = adminFiles(key).default;

	for(var  a in menuLocal){
		storeRouter.children.push(menuLocal[a]);
	}
})
// //使用接口获取数据
// axios.post(process.env.VUE_APP_BASE_API + "/"+'housekeeping/getPages', {type:'routing'},{})
// 	.then(response => {
// 		if(response.data.code === 200){
// 			var list = response.data.data;
// 			//let routerLists = [];
// 			list.forEach(function (value) {
// 				let childrenList = [];
// 				if(value.children.length > 0){
// 					value.children.forEach(function (val){
// 						var children=  {
// 							path:val.routing_path,
// 							component:()=>import(val.component),
// 							meta: {title:val.title},
// 							name:val.name,
// 						}
// 						childrenList.push(children);
// 					})
// 				}
// 				var pData = {
// 					path:value.routing_path,
// 					component:()=>import(value.component),
// 					meta: {title:value.title},
// 					name:value.name,
// 					children:childrenList
// 				};
// 				console.log(pData);
// 				storeRouter.children.push(pData)
// 				//routerLists.push(pData)
// 			})
//
// 		}
// 	})
// .catch((msg) => {
// 	return Promise.reject(msg || 'error')
// });

routerList.push(storeRouter);


const routerObj = new VueRouter({
    routes:routerList
});


routerObj.beforeEach((to, from, next) => {
     if(to.name == null){
        next({
            path:'/login'
        });
     }else{
       document.title =  to.meta.title + ' - 管理后台'
       next();
     }
})

export default routerObj;
